import Header from "./Header"; //Include Header
import Footer from "./Footer"; //Include Footer
import Home from "./Home";
import Shop from "./Shop";
import SingleProduct from "./SingleProduct";
import Checkout from "./Checkout";
import Cart from "./Cart";
import Login from "./Login";
import Signup from "./Signup";
import ForgotPassword from "./ForgotPassword";
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
  Outlet,
  useLocation,
  Navigate,
} from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store/mainStore";
import ClockProduct from "./ClockProduct";
import EditProduct from "./EditProduct";

function App() {
  const UnkownRouteHandler = ({ to }) => {
    const prevRoute = useLocation();
    return <Navigate to={to} state={{ prevRoute }} replace />;
  };

  return (
    <div className="App">
      <Provider store={store}>
        <BrowserRouter>
          <Header></Header>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/shop" element={<Shop />} />
            <Route path="/frames" element={<SingleProduct />} />
            <Route path="/checkout" element={<Checkout />} />
            <Route path="/cart" element={<Cart />} />
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/clock" element={<ClockProduct />} />
            <Route path="/Edit" element={<EditProduct />} />
            <Route path="*" element={<UnkownRouteHandler to="/login" />} />
          </Routes>
          <Footer></Footer>
        </BrowserRouter>
      </Provider>
    </div>
  );
}
export default App;
