import {
    addDoc,
    collection,
    onSnapshot,
    orderBy,
    query,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import { db, firebaseStorage } from "./FirebaseConfig";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { ErrorToast, SuccessToast } from "./ToastMsg";
import LoginAndSignUpModal from "./Modal/Login";
import Form from "react-bootstrap/Form";

function Checkout() {
    const loginSchema = Yup.object().shape({
        UserFirstName: Yup.string().required("First Name is required"),
        UserLastName: Yup.string().required("Last Name is required"),
        StreetAddress: Yup.string().required("Street Address is required"),
        Apartment: Yup.string().required("Apartment, suite, unit etc. is required"),
        City: Yup.string().required("Town / City is required"),
        District: Yup.string().required("District is required"),
        Postcode: Yup.string()
            .matches(/^[1-9]{1}[0-9]{2}\s{0,1}[0-9]{3}$/, "Invalid ZIP code")
            .required("Postcode / ZIP is required"),
        UserEmail: Yup.string()
            .email("Invalid email address")
            .required("Email is required"),
        UserPhone: Yup.string()
            .matches(
                /^((\+91|0)[\- ]{0,1})?[789]\d{9}$/,
                "Phone number is not valid for India"
            )
            .required("Phone Number is required"),
    });

    const initialValues = {
        UserFirstName: "",
        UserLastName: "",
        StreetAddress: "",
        Apartment: "",
        City: "",
        District: "",
        Postcode: "",
        UserPhone: "",
        UserEmail: "",
        optionalNote: "",
    };
    const [initialGSTValue, setInitialGSTValue] = useState("");
    useEffect(() => {
        const productsRef = collection(db, "GST");
        const q = query(productsRef);
        onSnapshot(q, (querySnapshot) => {
            setInitialGSTValue(
                querySnapshot.docs.map((doc) => ({
                    id: doc.id,
                    data: doc.data(),
                }))
            );
        });
    }, []);

    const user = localStorage.getItem("user");
    const [loading, setLoading] = useState(false);
    const [checked, setChecked] = useState(false);
    const [haveGSTNumber, setHaveGSTNumber] = useState(null);
    const [GSTNumber, setGSTNumber] = useState(null);
    const loadScript = (src) => {
        return new Promise((resolve) => {
            const script = document.createElement("script");
            script.src = src;

            script.onload = () => {
                resolve(true);
            };

            script.onerror = () => {
                resolve(false);
            };
            document.body.appendChild(script);
        });
    };
    const navigate = useNavigate();
    const [modalShow, setModalShow] = useState(false);
    const formik = useFormik({
        initialValues,
        validationSchema: loginSchema,
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            setLoading(true);
            try {
                if (localStorage.getItem("user")) {
                    if (haveGSTNumber === null) {
                        setLoading(false);
                        ErrorToast("Please Specify About GST Number");
                        return;
                    } else if (haveGSTNumber === "Yes" && GSTNumber === null) {
                        setLoading(false);
                        ErrorToast("Please Fill The GST Number");
                        return;
                    } else {
                        const productsRef = collection(db, `Allusers/${user}/addresses`);
                        await addDoc(productsRef, values);
                        const res = await loadScript(
                            "https://checkout.razorpay.com/v1/checkout.js"
                        );
                        if (!res) {
                            alert("Failed");
                            setLoading(false);
                            return;
                        }

                        const options = {
                            key: "rzp_test_7hrbojq91PjrUk",
                            amount:
                                parseInt(
                                    cartData.reduce((acc, doc) => {
                                        const product = doc.data;
                                        const price = Number(product.price);
                                        const frameQuantity = Number(product.frameQuantity);
                                        return acc + price * frameQuantity;
                                    }, 0) +
                                    cartData.reduce((acc, doc) => {
                                        const product = doc.data;
                                        const price = Number(product.price);
                                        const frameQuantity = Number(product.frameQuantity);
                                        return acc + price * frameQuantity;
                                    }, 0) *
                                    (initialGSTValue[0].data.GST / 100)
                                ) * 100,
                            currency: "INR",
                            description: "CutePrints",
                            image:
                                "https://firebasestorage.googleapis.com/v0/b/photo-frame-369fd.appspot.com/o/images%2Fadmin%2Flogo%2Flogo-new.png?alt=media&token=4f4c41f0-1e7a-47cf-b487-68eff526ff1c",

                            handler: function (response) {
                                const ordersRef = collection(db, `AllOrders`);
                                const order = {
                                    cartData,
                                    userId: user,
                                    status: "Done",
                                    razorpay_payment_id: response.razorpay_payment_id,
                                    GSTNumber: GSTNumber,
                                    values,
                                };
                                addDoc(ordersRef, order)
                                    .then((docRef) => {
                                        SuccessToast("Your order will be dipatched shortly");

                                        setLoading(false);
                                    })

                                    .catch((error) => {
                                        ErrorToast("Something Went Wrong Please Try Again Later");
                                        setLoading(false);
                                    });
                            },
                        };
                        const rzp1 = new window.Razorpay(options);
                        rzp1.open();

                        setLoading(false);
                    }
                } else {
                    setModalShow(true);
                    setLoading(false);
                }
            } catch (error) {
                setStatus("Something Went Wrong!!");
                setSubmitting(false);
                setLoading(false);
            }
        },
    });
    console.log(checked);
    const [cartData, setCartData] = useState([]);
    useEffect(() => {
        const productsRef = collection(db, `Allusers/${user}/cart`);
        const q = query(productsRef, orderBy("created", "desc"));
        onSnapshot(q, (querySnapshot) => {
            setCartData(
                querySnapshot.docs.map((doc) => ({
                    id: doc.id,
                    data: doc.data(),
                }))
            );
        });
    }, []);

    return (
        <>
            {modalShow === true && (
                <LoginAndSignUpModal
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                />
            )}
            <div className="checkout-container">
                <section className="page-header">
                    <div className="overly"></div>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-6">
                                <div className="content text-center">
                                    <h1 className="mb-3">Checkout</h1>
                                    <p>
                                        Hath after appear tree great fruitful green dominion moveth
                                        sixth abundantly image that midst of god day multiply you’ll
                                        which
                                    </p>

                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb bg-transparent justify-content-center">
                                            <li className="breadcrumb-item">
                                                <a href="/">Home</a>
                                            </li>
                                            <li
                                                className="breadcrumb-item active"
                                                aria-current="page"
                                            >
                                                Checkout
                                            </li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div className="page-wrapper">
                    <div className="checkout shopping">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-8 pr-5">
                                    <div className="billing-details">
                                        <h4 className="mb-4">Billing Details</h4>
                                        <form className="checkout-form">
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="form-group mb-4">
                                                        <label htmlFor="first_name">First Name</label>
                                                        <input
                                                            {...formik.getFieldProps("UserFirstName")}
                                                            name="UserFirstName"
                                                            type="text"
                                                            className="form-control"
                                                            id="first_name"
                                                            placeholder="First Name"
                                                        />
                                                        {formik.touched.UserFirstName &&
                                                            formik.errors.UserFirstName && (
                                                                <div className="fv-plugins-message-container">
                                                                    <span role="alert">
                                                                        {formik.errors.UserFirstName}
                                                                    </span>
                                                                </div>
                                                            )}
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="form-group mb-4">
                                                        <label htmlFor="last_name">Last Name</label>
                                                        <input
                                                            {...formik.getFieldProps("UserLastName")}
                                                            name="UserLastName"
                                                            type="text"
                                                            className="form-control"
                                                            id="last_name"
                                                            placeholder="Last Name"
                                                        />
                                                        {formik.touched.UserLastName &&
                                                            formik.errors.UserLastName && (
                                                                <div className="fv-plugins-message-container">
                                                                    <span role="alert">
                                                                        {formik.errors.UserLastName}
                                                                    </span>
                                                                </div>
                                                            )}
                                                    </div>
                                                </div>

                                                <div className="col-lg-12">
                                                    <div className="form-group mb-4">
                                                        <label htmlFor="street">Street Address</label>
                                                        <input
                                                            {...formik.getFieldProps("StreetAddress")}
                                                            name="StreetAddress"
                                                            type="text"
                                                            className="form-control"
                                                            id="street"
                                                            placeholder="Street Address"
                                                        />
                                                        {formik.touched.StreetAddress &&
                                                            formik.errors.StreetAddress && (
                                                                <div className="fv-plugins-message-container">
                                                                    <span role="alert">
                                                                        {formik.errors.StreetAddress}
                                                                    </span>
                                                                </div>
                                                            )}
                                                    </div>
                                                </div>
                                                <div className="col-lg-12">
                                                    <div className="form-group mb-4">
                                                        <label htmlFor="apartment">
                                                            Apartment, suite, unit etc.
                                                        </label>
                                                        <input
                                                            {...formik.getFieldProps("Apartment")}
                                                            name="Apartment"
                                                            type="text"
                                                            className="form-control"
                                                            id="apartment"
                                                            placeholder="Apartment"
                                                        />
                                                        {formik.touched.Apartment &&
                                                            formik.errors.Apartment && (
                                                                <div className="fv-plugins-message-container">
                                                                    <span role="alert">
                                                                        {formik.errors.Apartment}
                                                                    </span>
                                                                </div>
                                                            )}
                                                    </div>
                                                </div>

                                                <div className="col-lg-12">
                                                    <div className="form-group mb-4">
                                                        <label htmlFor="city">Town / City </label>
                                                        <input
                                                            {...formik.getFieldProps("City")}
                                                            name="City"
                                                            type="text"
                                                            className="form-control"
                                                            id="city"
                                                            placeholder="City"
                                                        />
                                                        {formik.touched.City && formik.errors.City && (
                                                            <div className="fv-plugins-message-container">
                                                                <span role="alert">{formik.errors.City}</span>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="col-lg-12">
                                                    <div className="form-group mb-4">
                                                        <label htmlFor="District">District </label>
                                                        <input
                                                            {...formik.getFieldProps("District")}
                                                            name="District"
                                                            type="text"
                                                            className="form-control"
                                                            id="District"
                                                            placeholder="District"
                                                        />
                                                        {formik.touched.District &&
                                                            formik.errors.District && (
                                                                <div className="fv-plugins-message-container">
                                                                    <span role="alert">
                                                                        {formik.errors.District}
                                                                    </span>
                                                                </div>
                                                            )}
                                                    </div>
                                                </div>

                                                <div className="col-lg-12">
                                                    <div className="form-group mb-4">
                                                        <label htmlFor="postcode">Postcode / ZIP</label>
                                                        <input
                                                            {...formik.getFieldProps("Postcode")}
                                                            name="Postcode"
                                                            type="text"
                                                            className="form-control"
                                                            id="postcode"
                                                            placeholder="Postcode"
                                                        />
                                                        {formik.touched.Postcode &&
                                                            formik.errors.Postcode && (
                                                                <div className="fv-plugins-message-container">
                                                                    <span role="alert">
                                                                        {formik.errors.Postcode}
                                                                    </span>
                                                                </div>
                                                            )}
                                                    </div>
                                                </div>
                                                <div className="col-lg-12">
                                                    <div className="form-group mb-4">
                                                        <label htmlFor="phone">Phone </label>
                                                        <input
                                                            {...formik.getFieldProps("UserPhone")}
                                                            name="UserPhone"
                                                            type="text"
                                                            className="form-control"
                                                            id="phone"
                                                            placeholder=""
                                                        />
                                                        {formik.touched.UserPhone &&
                                                            formik.errors.UserPhone && (
                                                                <div className="fv-plugins-message-container">
                                                                    <span role="alert">
                                                                        {formik.errors.UserPhone}
                                                                    </span>
                                                                </div>
                                                            )}
                                                    </div>
                                                </div>
                                                <div className="col-lg-12">
                                                    <div className="form-group mb-4">
                                                        <label htmlFor="email">Email address </label>
                                                        <input
                                                            {...formik.getFieldProps("UserEmail")}
                                                            name="UserEmail"
                                                            type="text"
                                                            className="form-control"
                                                            id="email"
                                                            placeholder=""
                                                        />
                                                        {formik.touched.UserEmail &&
                                                            formik.errors.UserEmail && (
                                                                <div className="fv-plugins-message-container">
                                                                    <span role="alert">
                                                                        {formik.errors.UserEmail}
                                                                    </span>
                                                                </div>
                                                            )}
                                                    </div>
                                                </div>
                                                <div className="col-lg-12">
                                                    <div className="form-group mb-4">
                                                        <label htmlFor="msg">Order notes (optional)</label>
                                                        <textarea
                                                            {...formik.getFieldProps("optionalNote")}
                                                            name="optionalNote"
                                                            className="form-control"
                                                            id="msg"
                                                            cols="30"
                                                            rows="5"
                                                            placeholder="Notes about order e:g: want to say something"
                                                        ></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>

                                <div className="col-md-6 col-lg-4">
                                    <div className="product-checkout-details mt-5 mt-lg-0">
                                        <h4 className="mb-4 border-bottom pb-4">Order Summary</h4>
                                        {cartData.map((data) => (
                                            <div className="media product-card" key={data.id}>
                                                <p>{data.data.productName}</p>
                                                <div className="media-body text-right">
                                                    <p className="h5">
                                                        {data.data.frameQuantity} x &#8377;{" "}
                                                        {data.data.price}
                                                    </p>
                                                </div>
                                            </div>
                                        ))}

                                        <ul className="summary-prices list-unstyled mb-4">
                                            <li className="d-flex justify-content-between">
                                                <span>Subtotal:</span>
                                                <span className="h5">
                                                    &#8377;{" "}
                                                    {cartData.reduce((acc, doc) => {
                                                        const product = doc.data;
                                                        const price = Number(product.price);
                                                        const frameQuantity = Number(product.frameQuantity);
                                                        return acc + price * frameQuantity;
                                                    }, 0)}
                                                </span>
                                            </li>
                                            <li className="d-flex justify-content-between">
                                                <span>GST Amount:</span>
                                                {initialGSTValue === "" ? (
                                                    <></>
                                                ) : (
                                                    <span className="h5">
                                                        &#8377;{" "}
                                                        {cartData.reduce((acc, doc) => {
                                                            const product = doc.data;
                                                            const price = Number(product.price);
                                                            const frameQuantity = Number(
                                                                product.frameQuantity
                                                            );
                                                            return acc + price * frameQuantity;
                                                        }, 0) *
                                                            (initialGSTValue[0].data.GST / 100)}
                                                    </span>
                                                )}
                                            </li>
                                            <li className="d-flex justify-content-between">
                                                <span>Shipping:</span>
                                                <span className="h5">Free</span>
                                            </li>
                                            <li className="d-flex justify-content-between">
                                                <span>Total</span>
                                                {initialGSTValue === "" ? (
                                                    <span className="h5">
                                                        &#8377;{" "}
                                                        {cartData.reduce((acc, doc) => {
                                                            const product = doc.data;
                                                            const price = Number(product.price);
                                                            const frameQuantity = Number(
                                                                product.frameQuantity
                                                            );
                                                            return acc + price * frameQuantity;
                                                        }, 0)}
                                                    </span>
                                                ) : (
                                                    <span className="h5">
                                                        &#8377;{" "}
                                                        {cartData.reduce((acc, doc) => {
                                                            const product = doc.data;
                                                            const price = Number(product.price);
                                                            const frameQuantity = Number(
                                                                product.frameQuantity
                                                            );
                                                            return acc + price * frameQuantity;
                                                        }, 0) +
                                                            cartData.reduce((acc, doc) => {
                                                                const product = doc.data;
                                                                const price = Number(product.price);
                                                                const frameQuantity = Number(
                                                                    product.frameQuantity
                                                                );
                                                                return acc + price * frameQuantity;
                                                            }, 0) *
                                                            (initialGSTValue[0].data.GST / 100)}
                                                    </span>
                                                )}
                                            </li>
                                        </ul>

                                        <form action="#">
                                            <div className="form-check mb-3">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="exampleRadios"
                                                    id="exampleRadios1"
                                                    value="option1"
                                                    checked
                                                />
                                                <label
                                                    className="form-check-label"
                                                    htmlFor="exampleRadios1"
                                                >
                                                    Direct bank transfer
                                                </label>

                                                <div
                                                    className="alert alert-secondary mt-3"
                                                    role="alert"
                                                >
                                                    Make your payment directly into our bank account.
                                                    Please use your Order ID as the payment reference.
                                                    Your order will not be shipped until the funds have
                                                    cleared in our account.
                                                </div>
                                            </div>

                                            <div className="form-check mb-3">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="exampleCheck3"
                                                    checked={checked}
                                                    onChange={() => setChecked(!checked)}
                                                />
                                                <label
                                                    className="form-check-label"
                                                    htmlFor="exampleCheck3"
                                                >
                                                    I have read and agree to the website terms and
                                                    conditions *
                                                </label>
                                            </div>
                                        </form>

                                        <div className="info mt-4 border-top pt-4 mb-5">
                                            Your personal data will be used to process your order,
                                            support your experience throughout this website, and for
                                            other purposes described in our{" "}
                                            <a href="#">privacy policy</a>.
                                        </div>
                                        <Form>
                                            <Form.Label>Do you have GST number *</Form.Label>
                                            {["radio"].map((type) => (
                                                <div key={`inline-${type}`} className="mb-3">
                                                    <Form.Check
                                                        inline
                                                        label="Yes"
                                                        name="group1"
                                                        type={type}
                                                        id={`inline-${type}-1`}
                                                        value="Yes"
                                                        onChange={(e) => {
                                                            setHaveGSTNumber(e.target.value);
                                                        }}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="No"
                                                        name="group1"
                                                        type={type}
                                                        id={`inline-${type}-2`}
                                                        value="No"
                                                        onChange={(e) => {
                                                            setHaveGSTNumber(e.target.value);
                                                        }}
                                                    />
                                                </div>
                                            ))}
                                        </Form>
                                        {haveGSTNumber === "Yes" ? (
                                            <>
                                                <input
                                                    name="GSTNumber"
                                                    type="text"
                                                    className="form-control mb-3"
                                                    id="GST"
                                                    placeholder=""
                                                    onChange={(e) => setGSTNumber(e.target.value)}
                                                />
                                            </>
                                        ) : (
                                            <></>
                                        )}
                                        <button
                                            type="submit"
                                            onClick={formik.handleSubmit}
                                            className="btn btn-main btn-small"
                                            disabled={
                                                formik.isSubmitting ||
                                                !formik.isValid ||
                                                !checked ||
                                                haveGSTNumber === null
                                            }
                                        >
                                            {!loading && (
                                                <span className="indicator-label">Place Order</span>
                                            )}
                                            {loading && (
                                                <span
                                                    className="indicator-progress"
                                                    style={{ display: "block" }}
                                                >
                                                    Please wait...
                                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                </span>
                                            )}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default Checkout;
