// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDGZvM-aarkgXasPykcaMPkn2igBWwHHs8",
    authDomain: "photo-frame-369fd.firebaseapp.com",
    projectId: "photo-frame-369fd",
    storageBucket: "photo-frame-369fd.appspot.com",
    messagingSenderId: "22483861504",
    appId: "1:22483861504:web:cfede69e50cd4357fcd8e5",
    measurementId: "G-7HH5DVPHDC",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const firebaseStorage = getStorage(app);
export const analytics = getAnalytics(app);
export default app;
