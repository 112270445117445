import { AnyAction, combineReducers, configureStore } from "@reduxjs/toolkit";
import CartSlice from "./CartSlice";
import EditSlice from "./EditSlice";

const store = configureStore({
  reducer: {
    cart: CartSlice.reducer,
    edit: EditSlice.reducer,
  },
});

export default store;
