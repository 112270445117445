import { createSlice } from "@reduxjs/toolkit";

// Define a type for the slice state
// interface checkoutInterface {
//     bankData: CashinBank[]
// }

// Define the initial state using that type
const initialState = {
    cartItems: [],
};

const CartSlice = createSlice({
    name: "Cart",
    initialState,
    reducers: {
        onAddCartItems(state, action) {
            state.cartItems = action.payload.items;
            console.log(state.cartItems);
        },
        onCartClear(state, action) {
            state.cartItems = [];
        },
    },
});

export const CartActions = CartSlice.actions;

export default CartSlice;
