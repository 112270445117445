import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    productId: "",
};

const EditSlice = createSlice({
    name: "productId",
    initialState,
    reducers: {
        onEditCartItem(state, action) {
            state.productId = action.payload.id;
        },
        onCartClear(state, action) {
            state.productId = "";
        },
    },
});

export const EditActions = EditSlice.actions;

export default EditSlice;
