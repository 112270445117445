import "jquery/dist/jquery.slim.min.js";
import "popper.js/dist/umd/popper.min.js";
import "bootstrap/dist/js/bootstrap.min.js";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import {
    collection,
    deleteDoc,
    doc,
    onSnapshot,
    orderBy,
    query,
} from "firebase/firestore";
import { db, firebaseStorage } from "./FirebaseConfig";
import { deleteObject, ref } from "firebase/storage";
function Header() {
    const user = localStorage.getItem("user");
    const [cartData, setCartData] = useState([]);
    const navigate = useNavigate();
    const [login, setLogin] = useState(!!user);
    useEffect(() => {
        const productsRef = collection(db, `Allusers/${user}/cart`);
        const q = query(productsRef, orderBy("created", "desc"));
        onSnapshot(q, (querySnapshot) => {
            setCartData(
                querySnapshot.docs.map((doc) => ({
                    id: doc.id,
                    data: doc.data(),
                }))
            );
        });
    }, [user]);

    const handleDelete = async (name, id) => {
        const DocRef = doc(db, `Allusers/${user}/cart`, id);
        const imageRef = ref(firebaseStorage, `images/${user}/${name}`);
        try {
            await deleteDoc(DocRef);
            await deleteObject(imageRef);
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <nav
            className="navbar navbar-expand-lg navbar-light bg-white w-100 navigation"
            id="navbar"
        >
            <div className="container">
                <Link className="navbar-brand font-weight-bold" to={{ pathname: "/" }}>
                    <img src="/assets/images/png.png" className="logo-main" />
                </Link>
                <button
                    className="navbar-toggler"
                    type="button"
                    data-toggle="collapse"
                    data-target="#main-navbar"
                    aria-controls="main-navbar"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse " id="main-navbar">
                    <ul className="navbar-nav mx-auto">
                        <li class="nav-item active">
                            <Link class="nav-link" to={{ pathname: "/" }}>
                                Home
                            </Link>
                        </li>
                        <li class="nav-item">
                            <Link class="nav-link" to={{ pathname: "/" }}>
                                About Us
                            </Link>
                        </li>

                        <li class="nav-item dropdown dropdown-slide">
                            <a
                                class="nav-link dropdown-toggle"
                                href="#"
                                id="navbarDropdown3"
                                role="button"
                                data-delay="350"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                            >
                                Shop
                            </a>
                            <ul class="dropdown-menu" aria-labelledby="navbarDropdown3">
                                <li>
                                    <Link to={{ pathname: "/frames" }}>Acrylic Photo</Link>
                                </li>
                                <li>
                                    <Link to={{ pathname: "/clock" }}>Acrylic Wall Clock</Link>
                                </li>
                                <li>
                                    <Link to={{ pathname: "/checkout" }}>Checkout</Link>
                                </li>
                                <li>
                                    <Link to={{ pathname: "/cart" }}>Cart</Link>
                                </li>
                            </ul>
                        </li>

                        <li class="nav-item dropdown dropdown-slide">
                            <a
                                class="nav-link dropdown-toggle"
                                href="#"
                                id="navbarDropdown5"
                                role="button"
                                data-delay="350"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                            >
                                Account
                            </a>
                            <ul class="dropdown-menu" aria-labelledby="navbarDropdown5">
                                {localStorage.getItem("user") ? (
                                    <>
                                        <li>
                                            <a
                                                onClick={() => {
                                                    localStorage.clear();
                                                    window.location.reload();
                                                }}
                                                style={{ cursor: "pointer" }}
                                            >
                                                Logout
                                            </a>
                                        </li>
                                    </>
                                ) : (
                                    <>
                                        <li>
                                            <Link to={{ pathname: "/login" }}>Login Page</Link>
                                        </li>
                                        <li>
                                            <Link to={{ pathname: "/signup" }}>SignUp Page</Link>
                                        </li>
                                    </>
                                )}
                            </ul>
                        </li>
                    </ul>
                </div>
                <ul
                    className="top-menu list-inline mb-0 d-none d-lg-block"
                    id="top-menu"
                >
                    <li className="dropdown cart-nav dropdown-slide list-inline-item">
                        <a
                            href="#"
                            className="dropdown-toggle cart-icon"
                            data-toggle="dropdown"
                            data-hover="dropdown"
                        >
                            <i className="tf-ion-android-cart"></i>
                        </a>
                        <div className="dropdown-menu cart-dropdown">
                            {cartData.map((prod) => (
                                <div className="media" key={prod.id}>
                                    <a href="/product-single">
                                        <img
                                            className="media-object img- mr-3"
                                            src={prod.data.image}
                                            alt="product"
                                        />
                                    </a>
                                    <div className="media-body">
                                        <h6>{prod.data.productName}</h6>
                                        <div className="cart-price">
                                            <span>{prod.data.frameQuantity} x</span>
                                            <span>{prod.data.price}</span>
                                        </div>
                                    </div>
                                    <a
                                        className="remove"
                                        onClick={() => {
                                            handleDelete(prod.data.imageName, prod.id);
                                        }}
                                    >
                                        <i className="tf-ion-close"></i>
                                    </a>
                                </div>
                            ))}

                            <div className="cart-summary">
                                <span className="h6">Total</span>
                                <span className="total-price h6">
                                    {" "}
                                    &#8377;{" "}
                                    {cartData.reduce((acc, doc) => {
                                        const product = doc.data;
                                        const price = Number(product.price);
                                        const frameQuantity = Number(product.frameQuantity);
                                        return acc + price * frameQuantity;
                                    }, 0)}
                                </span>
                                <div className="text-center cart-buttons mt-3">
                                    <a
                                        onClick={() => navigate("/cart")}
                                        className="btn btn-small btn-transparent btn-block"
                                    >
                                        View Cart
                                    </a>
                                    <a
                                        onClick={() => navigate("/checkout")}
                                        className="btn btn-small btn-main btn-block"
                                    >
                                        Checkout
                                    </a>
                                </div>
                            </div>
                        </div>
                    </li>
                    {/* <li className="dropdown cart-nav dropdown-slide list-inline-item">
                        <a
                            href="#"
                            className="dropdown-toggle cart-icon"
                            data-toggle="dropdown"
                            data-hover="dropdown"
                        >
                            <i className="tf-ion-ios-person mr-3"></i>
                        </a>
                        {localStorage.getItem("user") ? (
                            <div className="dropdown-menu cart-dropdown">
                                <div>
                                    <li>
                                        <a
                                            onClick={() => {
                                                localStorage.clear();
                                                window.location.reload();
                                            }}
                                        >
                                            Logout
                                        </a>
                                    </li>
                                </div>
                            </div>
                        ) : (
                            <div className="dropdown-menu cart-dropdown">
                                <div>
                                    <li>
                                        <a
                                            onClick={() => {
                                                navigate("/login");
                                            }}
                                        >
                                            Login
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            onClick={() => {
                                                navigate("/signup");
                                            }}
                                        >
                                            SignUp
                                        </a>
                                    </li>
                                </div>
                            </div>
                        )}
                    </li> */}
                </ul>
            </div>
        </nav>
    );
}
export default Header;
