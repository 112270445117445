import React, { useState } from "react";
import * as Yup from "yup";
import {
    NavLink,
    useLocation,
    useMatch,
    useNavigate,
    useParams,
    usePrevious,
} from "react-router-dom";
import { useFormik } from "formik";
import { signInWithEmailAndPassword, updateProfile } from "firebase/auth";
import { auth } from "./FirebaseConfig";
import { ErrorToast } from "./ToastMsg";

const loginSchema = Yup.object().shape({
    UserEmail: Yup.string().required("Email is required"),
    UserPass: Yup.string().required("Password is required"),
});

const initialValues = {
    UserEmail: "",
    UserPass: "",
};

function Login() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);


    const formik = useFormik({
        initialValues,
        validationSchema: loginSchema,
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            setLoading(true);
            try {
                if (values.UserEmail.length != 0) {
                    await signInWithEmailAndPassword(
                        auth,
                        values.UserEmail,
                        values.UserPass
                    )
                        .then(async (userCredential) => {
                            // Signed in
                            // await updateProfile(userCredential, { displayName: values.UserName });
                            const user = userCredential.user;
                            console.log(user.uid);
                            localStorage.setItem("user", user.uid);
                            navigate("/");
                            // ...
                        })
                        .catch((error) => {
                            const errorCode = error.code;
                            const errorMessage = error.message;
                            console.log(errorCode, errorMessage);
                            ErrorToast(errorMessage);
                            // ..
                        });
                }
            } catch (error) {
                setStatus("The login details are incorrect");
                ErrorToast("The login details are incorrect");
                setSubmitting(false);
                setLoading(false);
            }
        },
    });

    return (
        <div className="login-container">
            <div className="account section">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-6">
                            <div className="login-form border p-5">
                                <div className="text-center heading">
                                    <h2 className="mb-2">Login</h2>
                                    <p className="lead">
                                        Don’t have an account?{" "}
                                        <a href="/signup">Create a free account</a>
                                    </p>
                                </div>

                                <form onSubmit={formik.handleSubmit}>
                                    <div className="form-group mb-4">
                                        <label for="#">Enter username</label>
                                        <input
                                            {...formik.getFieldProps("UserEmail")}
                                            type="email"
                                            name="UserEmail"
                                            className="form-control"
                                            placeholder="Enter Username"
                                        />
                                        {formik.touched.UserEmail && formik.errors.UserEmail && (
                                            <div className="fv-plugins-message-container">
                                                <span role="alert">{formik.errors.UserEmail}</span>
                                            </div>
                                        )}
                                    </div>
                                    <div className="form-group">
                                        <label for="#">Enter Password</label>

                                        <input
                                            {...formik.getFieldProps("UserPass")}
                                            type="password"
                                            name="UserPass"
                                            className="form-control"
                                            placeholder="Enter Password"
                                        />
                                        {formik.touched.UserPass && formik.errors.UserPass && (
                                            <div className="fv-plugins-message-container">
                                                <span role="alert">{formik.errors.UserPass}</span>
                                            </div>
                                        )}
                                        <a className="float-right mb-3 mt-2" href="">
                                            Forget password?
                                        </a>
                                    </div>

                                    <button
                                        type="submit"
                                        className="btn btn-main mt-3 btn-block"
                                        disabled={formik.isSubmitting || !formik.isValid}
                                    >
                                        {!loading && <span className="indicator-label">Login</span>}
                                        {loading && (
                                            <span
                                                className="indicator-progress"
                                                style={{ display: "block" }}
                                            >
                                                Please wait...
                                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                            </span>
                                        )}
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Login;
