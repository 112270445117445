import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
} from "firebase/auth";
import { auth, db } from "../FirebaseConfig";
import { addDoc, collection } from "firebase/firestore";
import { ErrorToast } from "../ToastMsg";

function LoginAndSignUpModal(props) {
    const SignUpSchema = Yup.object().shape({
        UserEmail: Yup.string().required("Email is required"),
        UserName: Yup.string().required("UserName is required"),
        UserPass: Yup.string().required("Password is required"),
        UserPassConfirm: Yup.string().required("Password is required"),
    });

    const initialValuesSignUp = {
        UserEmail: "",
        UserName: "",
        UserPass: "",
        UserPassConfirm: "",
    };

    const loginSchema = Yup.object().shape({
        UserLoginEmail: Yup.string().required("Email is required"),
        UserLoginPass: Yup.string().required("Password is required"),
    });

    const initialValuesLogin = {
        UserLoginEmail: "",
        UserLoginPass: "",
    };

    const [loading, setLoading] = useState(false);

    const formikSignUp = useFormik({
        initialValues: initialValuesSignUp,
        validationSchema: SignUpSchema,
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            console.log("here");
            setLoading(true);
            try {
                console.log(values);
                if (values.UserEmail.length != 0) {
                    if (values.UserPass == values.UserPassConfirm) {
                        await createUserWithEmailAndPassword(
                            auth,
                            values.UserEmail,
                            values.UserPass
                        )
                            .then(async (userCredential) => {
                                // Signed in
                                console.log(userCredential);
                                // await updateProfile(userCredential, {
                                //     displayName: values.UserName,
                                // });
                                const usersRef = collection(db, `AllActiveUsers`);
                                await addDoc(usersRef, {
                                    uid: userCredential.user.uid,
                                    email: userCredential.user.email,
                                    displayName: values.UserName,
                                });
                                const user = userCredential.user;
                                localStorage.setItem("user", user.uid);
                                window.dispatchEvent(new Event("storage"));
                                console.log(user.uid);
                                props.onHide();
                                window.location.reload();
                            })
                            .catch((error) => {
                                const errorCode = error.code;
                                const errorMessage = error.message;
                                console.log(errorCode, errorMessage);
                                setLoading(false);
                                // ..
                            });
                    }
                }
            } catch (error) {
                setStatus("The login details are incorrect");
                setSubmitting(false);
                setLoading(false);
            }
        },
    });

    const formikLogin = useFormik({
        initialValues: initialValuesLogin,
        validationSchema: loginSchema,
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            setLoading(true);
            console.log(values);
            try {
                if (values.UserLoginEmail.length != 0) {
                    await signInWithEmailAndPassword(
                        auth,
                        values.UserLoginEmail,
                        values.UserLoginPass
                    )
                        .then(async (userCredential) => {
                            // Signed in
                            // await updateProfile(userCredential, { displayName: values.UserName });
                            const user = userCredential.user;
                            console.log(user.uid);
                            localStorage.setItem("user", user.uid);
                            props.onHide();
                            window.location.reload();

                            // ...
                        })
                        .catch((error) => {
                            const errorCode = error.code;
                            const errorMessage = error.message;
                            console.log(errorCode, errorMessage);
                            ErrorToast(errorMessage);
                            // ..
                        });
                }
            } catch (error) {
                setStatus("The login details are incorrect");
                ErrorToast("The login details are incorrect");
                setSubmitting(false);
                setLoading(false);
            }
        },
    });

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body>
                <div className="row">
                    <div className="col">
                        <h3>LOGIN</h3>
                        <Form>
                            <Form.Group className="mb-3">
                                <Form.Label>Email address *</Form.Label>
                                <Form.Control
                                    {...formikLogin.getFieldProps("UserLoginEmail")}
                                    name="UserLoginEmail"
                                    type="email"
                                    placeholder="name@example.com"
                                    autoFocus
                                    style={{ borderRadius: "5px" }}
                                />
                                {formikLogin.touched.UserLoginEmail &&
                                    formikLogin.errors.UserLoginEmail && (
                                        <div className="fv-plugins-message-container">
                                            <span role="alert">
                                                {formikLogin.errors.UserLoginEmail}
                                            </span>
                                        </div>
                                    )}
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Password *</Form.Label>
                                <Form.Control
                                    {...formikLogin.getFieldProps("UserLoginPass")}
                                    name="UserLoginPass"
                                    type="password"
                                    placeholder="**********"
                                    autoFocus
                                    style={{ borderRadius: "5px" }}
                                />
                                {formikLogin.touched.UserLoginPass &&
                                    formikLogin.errors.UserLoginPass && (
                                        <div className="fv-plugins-message-container">
                                            <span role="alert">
                                                {formikLogin.errors.UserLoginPass}
                                            </span>
                                        </div>
                                    )}
                            </Form.Group>
                        </Form>
                        <Button
                            style={{
                                borderRadius: "10px",
                                backgroundColor: "#fb5c42",
                                borderColor: "#fb5c42",
                            }}
                            onClick={formikLogin.handleSubmit}
                            disabled={formikLogin.isSubmitting || !formikLogin.isValid}
                        >
                            {!loading && <span className="indicator-label">Login</span>}
                            {loading && (
                                <span
                                    className="indicator-progress"
                                    style={{ display: "block" }}
                                >
                                    Please wait...
                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span>
                            )}
                        </Button>
                        <p>Lost your password?</p>
                    </div>
                    <div className="col">
                        <h3>REGISTER</h3>
                        <Form>
                            <Form.Group className="mb-3">
                                <Form.Label>Email address *</Form.Label>
                                <Form.Control
                                    {...formikSignUp.getFieldProps("UserEmail")}
                                    type="email"
                                    name="UserEmail"
                                    placeholder="name@example.com"
                                    autoFocus
                                    style={{ borderRadius: "5px" }}
                                />
                                {formikSignUp.touched.UserEmail &&
                                    formikSignUp.errors.UserEmail && (
                                        <div className="fv-plugins-message-container">
                                            <span role="alert">{formikSignUp.errors.UserEmail}</span>
                                        </div>
                                    )}
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Username *</Form.Label>
                                <Form.Control
                                    {...formikSignUp.getFieldProps("UserName")}
                                    type="text"
                                    placeholder="username"
                                    name="UserName"
                                    autoFocus
                                    style={{ borderRadius: "5px" }}
                                />
                                {formikSignUp.touched.UserName &&
                                    formikSignUp.errors.UserName && (
                                        <div className="fv-plugins-message-container">
                                            <span role="alert">{formikSignUp.errors.UserName}</span>
                                        </div>
                                    )}
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Password *</Form.Label>
                                <Form.Control
                                    {...formikSignUp.getFieldProps("UserPass")}
                                    type="password"
                                    name="UserPass"
                                    autoFocus
                                    style={{ borderRadius: "5px" }}
                                    placeholder="**********"
                                />
                                {formikSignUp.touched.UserPass &&
                                    formikSignUp.errors.UserPass && (
                                        <div className="fv-plugins-message-container">
                                            <span role="alert">{formikSignUp.errors.UserPass}</span>
                                        </div>
                                    )}
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Confirm Password *</Form.Label>
                                <Form.Control
                                    {...formikSignUp.getFieldProps("UserPassConfirm")}
                                    type="password"
                                    name="UserPassConfirm"
                                    autoFocus
                                    style={{ borderRadius: "5px" }}
                                    placeholder="**********"
                                />
                                {formikSignUp.touched.UserPassConfirm &&
                                    formikSignUp.errors.UserPassConfirm && (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                <span role="alert">
                                                    {formikSignUp.errors.UserPassConfirm}
                                                </span>
                                            </div>
                                        </div>
                                    )}
                            </Form.Group>
                        </Form>
                        <Button
                            style={{
                                borderRadius: "10px",
                                backgroundColor: "#fb5c42",
                                borderColor: "#fb5c42",
                            }}
                            onClick={formikSignUp.handleSubmit}
                            disabled={formikSignUp.isSubmitting || !formikSignUp.isValid}
                        >
                            {!loading && <span className="indicator-label">Register</span>}
                            {loading && (
                                <span
                                    className="indicator-progress"
                                    style={{ display: "block" }}
                                >
                                    Please wait...
                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span>
                            )}
                        </Button>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="primary"
                    style={{ borderRadius: "10px" }}
                    onClick={props.onHide}
                >
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default LoginAndSignUpModal;
