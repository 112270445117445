import React, { useState } from "react";
import * as Yup from "yup";
import { Navigate, NavLink, useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { createUserWithEmailAndPassword, updateProfile } from "firebase/auth";
import { auth, db } from "./FirebaseConfig";
import { addDoc, collection } from "firebase/firestore";

const loginSchema = Yup.object().shape({
    UserEmail: Yup.string().required("Email is required"),
    UserName: Yup.string().required("UserName is required"),
    UserPass: Yup.string().required("Password is required"),
    UserPassConfirm: Yup.string().required("Password is required"),
});

const initialValues = {
    UserEmail: "",
    UserName: "",
    UserPass: "",
    UserPassConfirm: "",
};

function SignUp() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const location = useLocation();
    const formik = useFormik({
        initialValues,
        validationSchema: loginSchema,
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            setLoading(true);
            try {
                if (values.UserEmail.length != 0) {
                    if (values.UserPass == values.UserPassConfirm) {
                        await createUserWithEmailAndPassword(
                            auth,
                            values.UserEmail,
                            values.UserPass
                        )
                            .then(async (userCredential) => {
                                // Signed in
                                console.log(userCredential);
                                // await updateProfile(userCredential, {
                                //     displayName: values.UserName,
                                // });
                                const usersRef = collection(db, `AllActiveUsers`);
                                await addDoc(usersRef, {
                                    email: userCredential.user.email,
                                    displayName: values.UserName,
                                });
                                const user = userCredential.user;
                                console.log(user.uid);
                                navigate("/login");
                            })
                            .catch((error) => {
                                const errorCode = error.code;
                                const errorMessage = error.message;
                                console.log(errorCode, errorMessage);
                                setLoading(false);
                                // ..
                            });
                    }
                }
            } catch (error) {
                setStatus("The login details are incorrect");
                setSubmitting(false);
                setLoading(false);
            }
        },
    });

    return (
        <div className="signUp-container">
            <div className="account section">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-6">
                            <div className="login-form border p-5">
                                <div className="text-center heading">
                                    <h2 className="mb-2">Sign Up</h2>
                                    <p className="lead">
                                        Already have an account? <a href="/login"> Login now</a>
                                    </p>
                                </div>

                                <form onSubmit={formik.handleSubmit}>
                                    <div className="form-group mb-4">
                                        <label for="#">Enter Email Address</label>
                                        <input
                                            {...formik.getFieldProps("UserEmail")}
                                            type="email"
                                            name="UserEmail"
                                            className="form-control"
                                            placeholder="Enter Email Address"
                                        />
                                        {formik.touched.UserEmail && formik.errors.UserEmail && (
                                            <div className="fv-plugins-message-container">
                                                <span role="alert">{formik.errors.UserEmail}</span>
                                            </div>
                                        )}
                                    </div>
                                    <div className="form-group mb-4">
                                        <label for="#">Enter username</label>
                                        <input
                                            {...formik.getFieldProps("UserName")}
                                            type="text"
                                            name="UserName"
                                            className="form-control"
                                            placeholder="Enter Password"
                                        />
                                        {formik.touched.UserName && formik.errors.UserName && (
                                            <div className="fv-plugins-message-container">
                                                <span role="alert">{formik.errors.UserName}</span>
                                            </div>
                                        )}
                                    </div>
                                    <div className="form-group mb-4">
                                        <label for="#">Enter Password</label>
                                        <input
                                            {...formik.getFieldProps("UserPass")}
                                            type="password"
                                            name="UserPass"
                                            className="form-control"
                                            placeholder="Enter Password"
                                        />
                                        {formik.touched.UserPass && formik.errors.UserPass && (
                                            <div className="fv-plugins-message-container">
                                                <span role="alert">{formik.errors.UserPass}</span>
                                            </div>
                                        )}
                                    </div>
                                    <div className="form-group">
                                        <label for="#">Confirm Password</label>
                                        <input
                                            {...formik.getFieldProps("UserPassConfirm")}
                                            type="password"
                                            name="UserPassConfirm"
                                            className="form-control"
                                            placeholder="Confirm Password"
                                        />
                                        {formik.touched.UserPassConfirm &&
                                            formik.errors.UserPassConfirm && (
                                                <div className="fv-plugins-message-container">
                                                    <div className="fv-help-block">
                                                        <span role="alert">
                                                            {formik.errors.UserPassConfirm}
                                                        </span>
                                                    </div>
                                                </div>
                                            )}
                                    </div>

                                    <button
                                        type="submit"
                                        className="btn btn-main mt-3 btn-block"
                                        disabled={formik.isSubmitting || !formik.isValid}
                                    // onClick={onSubmit}
                                    >
                                        {!loading && (
                                            <span className="indicator-label">Signup</span>
                                        )}
                                        {loading && (
                                            <span
                                                className="indicator-progress"
                                                style={{ display: "block" }}
                                            >
                                                Please wait...
                                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                            </span>
                                        )}
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default SignUp;
