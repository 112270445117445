import { useEffect, useState } from "react";
import {
    collection,
    query,
    orderBy,
    onSnapshot,
    doc,
    updateDoc,
    deleteDoc,
} from "firebase/firestore";
import { db, firebaseStorage } from "./FirebaseConfig";
import { ref, deleteObject } from "firebase/storage";
import { useNavigate } from "react-router-dom";

function Cart() {
    const user = localStorage.getItem("user");
    const [cartData, setCartData] = useState([]);
    const navigate = useNavigate();

    const [initialGSTValue, setInitialGSTValue] = useState("");
    useEffect(() => {
        const productsRef = collection(db, "GST");
        const q = query(productsRef);
        onSnapshot(q, (querySnapshot) => {
            setInitialGSTValue(
                querySnapshot.docs.map((doc) => ({
                    id: doc.id,
                    data: doc.data(),
                }))
            );
        });
    }, []);

    useEffect(() => {
        const productsRef = collection(db, `Allusers/${user}/cart`);
        const q = query(productsRef, orderBy("created", "desc"));
        onSnapshot(q, (querySnapshot) => {
            setCartData(
                querySnapshot.docs.map((doc) => ({
                    id: doc.id,
                    data: doc.data(),
                }))
            );
        });
    }, [user]);

    const handleChange = async (e, id) => {
        e.preventDefault();
        const DocRef = doc(db, `Allusers/${user}/cart`, id);
        try {
            await updateDoc(DocRef, {
                frameQuantity: e.target.value,
            });
        } catch (err) {
            console.log(err);
        }
    };

    const handleDelete = async (name, id) => {
        const DocRef = doc(db, `Allusers/${user}/cart`, id);
        const imageRef = ref(firebaseStorage, `images/${user}/${name}`);
        try {
            await deleteDoc(DocRef);
            await deleteObject(imageRef);
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <div className="checkout-container">
            <section className="page-header">
                <div className="overly"></div>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-6">
                            <div className="content text-center">
                                <h1 className="mb-3">Cart</h1>
                                Hath after appear tree great fruitful green dominion moveth
                                sixth abundantly image that midst of god day multiply you’ll
                                which
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb bg-transparent justify-content-center">
                                        <li className="breadcrumb-item">
                                            <a href="/">Home</a>
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page">
                                            Cart
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="cart shopping page-wrapper">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-12">
                            <div className="product-list">
                                <form className="cart-form">
                                    <table
                                        className="table shop_table shop_table_responsive cart"
                                        cellSpacing="0"
                                    >
                                        <thead>
                                            <tr>
                                                <th className="product-thumbnail"> </th>
                                                <th className="product-name">Product</th>
                                                <th className="product-name">Frame Colour</th>
                                                <th className="product-name">Frame Size</th>
                                                <th className="product-name">Frame Thickness</th>
                                                <th className="product-name">Image Text</th>
                                                <th className="product-name">Text Colour</th>
                                                <th className="product-price">Price</th>
                                                <th className="product-quantity">Quantity</th>
                                                <th className="product-subtotal">Total</th>
                                                <th className="product-remove"> </th>
                                                <th className="product-remove"> </th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {cartData.map((data) => (
                                                <tr className="cart_item" key={data.id}>
                                                    <td
                                                        className="product-thumbnail"
                                                        data-title="Thumbnail"
                                                    >
                                                        <a href="#">
                                                            <img
                                                                src={data.data.image}
                                                                className="attachment-woocommerce_thumbnail size-woocommerce_thumbnail"
                                                                alt=""
                                                            />
                                                        </a>
                                                    </td>

                                                    <td className="product-name" data-title="Product">
                                                        {data.data.productName}
                                                    </td>
                                                    <td
                                                        className="product-name"
                                                        data-title="Frame Colour"
                                                    >
                                                        <div className="colour__container">
                                                            <div
                                                                style={{
                                                                    height: "20px",
                                                                    width: "20px",
                                                                    backgroundColor: `${data.data.frameColor}`,
                                                                }}
                                                            ></div>
                                                        </div>
                                                    </td>
                                                    <td className="product-name " data-title="Frame Size">
                                                        {data.data.framesize}
                                                    </td>
                                                    <td
                                                        className="product-name "
                                                        data-title="Frame Thickness"
                                                    >
                                                        {data.data.frameThickness}
                                                    </td>
                                                    <td className="product-name" data-title="Image Text">
                                                        {data.data.imageText}
                                                    </td>
                                                    <td
                                                        className="product-name"
                                                        data-title="Image Colour"
                                                    >
                                                        {data.data.showTextInput === false ? (
                                                            ""
                                                        ) : (
                                                            <div className="colour__container">
                                                                <div
                                                                    style={{
                                                                        height: "20px",
                                                                        width: "20px",
                                                                        backgroundColor: `${data.data.textColorHexCode}`,
                                                                    }}
                                                                ></div>
                                                            </div>
                                                        )}
                                                    </td>
                                                    <td className="product-name" data-title="Price">
                                                        {data.data.price}
                                                    </td>
                                                    <td className="product-name" data-title="Quantity">
                                                        {data.data.frameQuantity}
                                                    </td>
                                                    <td className="product-name" data-title="Total">
                                                        {data.data.frameQuantity * data.data.price}
                                                    </td>
                                                    <td
                                                        className="product-remove"
                                                        data-title="Edit"
                                                        style={{ cursor: "pointer" }}
                                                    >
                                                        <a
                                                            className="remove"
                                                            aria-label="Edit this item"
                                                            data-product_id="30"
                                                            data-product_sku=""
                                                            href="/Edit"
                                                            onClick={() => {
                                                                localStorage.setItem("editProductId", data.id);
                                                                navigate("/Edit");
                                                            }}
                                                        >
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width="16"
                                                                height="16"
                                                                fill="currentColor"
                                                                class="bi bi-pen"
                                                                viewBox="0 0 16 16"
                                                            >
                                                                <path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001zm-.644.766a.5.5 0 0 0-.707 0L1.95 11.756l-.764 3.057 3.057-.764L14.44 3.854a.5.5 0 0 0 0-.708l-1.585-1.585z" />
                                                            </svg>
                                                        </a>
                                                    </td>
                                                    <td
                                                        className="product-remove"
                                                        data-title="Remove"
                                                        style={{ cursor: "pointer" }}
                                                    >
                                                        <a
                                                            className="remove"
                                                            aria-label="Remove this item"
                                                            data-product_id="30"
                                                            data-product_sku=""
                                                            onClick={() =>
                                                                handleDelete(data.data.imageName, data.id)
                                                            }
                                                        >
                                                            ×
                                                        </a>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-end">
                        <div className="col-lg-4">
                            <div className="cart-info card p-4 mt-4">
                                <h4 className="mb-4">Cart totals</h4>
                                <ul className="list-unstyled mb-4">
                                    <li className="d-flex justify-content-between pb-2 mb-3">
                                        <h5>Subtotal</h5>
                                        <span>
                                            &#8377;{" "}
                                            {cartData.reduce((acc, doc) => {
                                                const product = doc.data;
                                                const price = Number(product.price);
                                                const frameQuantity = Number(product.frameQuantity);
                                                return acc + price * frameQuantity;
                                            }, 0)}
                                        </span>
                                    </li>
                                    <li className="d-flex justify-content-between pb-2 mb-3">
                                        <h5>GST Amount</h5>
                                        {initialGSTValue === "" ? (
                                            <></>
                                        ) : (
                                            <span>
                                                &#8377;{" "}
                                                {cartData.reduce((acc, doc) => {
                                                    const product = doc.data;
                                                    const price = Number(product.price);
                                                    const frameQuantity = Number(product.frameQuantity);
                                                    return acc + price * frameQuantity;
                                                }, 0) *
                                                    (initialGSTValue[0].data.GST / 100)}
                                            </span>
                                        )}
                                    </li>
                                    <li className="d-flex justify-content-between pb-2 mb-3">
                                        <h5>Shipping</h5>
                                        <span>Free</span>
                                    </li>
                                    <li className="d-flex justify-content-between pb-2">
                                        <h5>Total</h5>
                                        {initialGSTValue === "" ? (
                                            <span>
                                                &#8377;{" "}
                                                {cartData.reduce((acc, doc) => {
                                                    const product = doc.data;
                                                    const price = Number(product.price);
                                                    const frameQuantity = Number(product.frameQuantity);
                                                    return acc + price * frameQuantity;
                                                }, 0)}
                                            </span>
                                        ) : (
                                            <span>
                                                &#8377;{" "}
                                                {cartData.reduce((acc, doc) => {
                                                    const product = doc.data;
                                                    const price = Number(product.price);
                                                    const frameQuantity = Number(product.frameQuantity);
                                                    return acc + price * frameQuantity;
                                                }, 0) +
                                                    cartData.reduce((acc, doc) => {
                                                        const product = doc.data;
                                                        const price = Number(product.price);
                                                        const frameQuantity = Number(product.frameQuantity);
                                                        return acc + price * frameQuantity;
                                                    }, 0) *
                                                    (initialGSTValue[0].data.GST / 100)}
                                            </span>
                                        )}
                                    </li>
                                </ul>
                                <button
                                    href="#"
                                    className="btn btn-main btn-small"
                                    onClick={() => navigate("/checkout")}
                                >
                                    Proceed to checkout
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}
export default Cart;
