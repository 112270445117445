import { Link, useNavigate } from "react-router-dom";

function Footer() {
    const navigate = useNavigate();
    return (
        <div className="footer-container">
            <footer className="footer">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-lg-4 col-sm-6 mb-5 mb-lg-0 text-center text-sm-left mr-auto">
                            <div className="footer-widget" style={{ marginTop: "-45px" }}>
                                <Link
                                    className="navbar-brand font-weight-bold"
                                    to={{ pathname: "/" }}
                                >
                                    <img src="/assets/images/png.png" className="logo-main" />
                                </Link>
                                <p className="lead">
                                    Iste dolores iure quis excepturi, deserunt praesentium.
                                </p>

                                <div className="">
                                    <p className="mb-0">
                                        <strong>Location : </strong>North Punjab ,INDIA
                                    </p>
                                    <p>
                                        <strong>Support Email : </strong> support@email.com
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-2 col-sm-6 mb-5 mb-lg-0 text-center text-sm-left">
                            <div className="footer-widget">
                                <h4 className="mb-4">Category</h4>
                                <ul className="pl-0 list-unstyled mb-0">
                                    <li>
                                        <a href="/frames" style={{ cursor: "pointer" }}>
                                            Acrylic Photo
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/clock" style={{ cursor: "pointer" }}>
                                            Acrylic Wall Clock
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>

            <div className="footer-btm py-4 ">
                <div className="container">
                    <div className="row ">
                        <div className="col-lg-6">
                            <p className="copyright mb-0 ">
                                @ Copyright Reserved to SoftEzi Solutions LLP
                            </p>
                        </div>
                        <div className="col-lg-6">
                            <ul className="list-inline mb-0 footer-btm-links text-lg-right mt-2 mt-lg-0">
                                <li className="list-inline-item">
                                    <a href="#">Privacy Policy</a>
                                </li>
                                <li className="list-inline-item">
                                    <a href="#">Terms &amp; Conditions</a>
                                </li>
                                <li className="list-inline-item">
                                    <a href="#">Cookie Policy</a>
                                </li>
                                <li className="list-inline-item">
                                    <a href="#">Terms of Sale</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Footer;
