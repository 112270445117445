import { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import { SketchPicker } from "react-color";
import reactCSS from "reactcss";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { db, firebaseStorage } from "./FirebaseConfig";
import { v4 } from "uuid";
import {
    collection,
    addDoc,
    Timestamp,
    query,
    onSnapshot,
} from "firebase/firestore";
import { ErrorToast, SuccessToast } from "./ToastMsg";
import { useNavigate } from "react-router-dom";
import LoginAndSignUpModal from "./Modal/Login";

function SingleProduct() {
    const [imageData, setImageData] = useState();
    const [imageUpload, setImageUpload] = useState(null);
    const containerRef = useRef([]);
    const canvasRefs = useRef([]);
    const [frameColor, setFrameColor] = useState("");
    const [imageText, setImageText] = useState("");
    const [showTextInput, setShowTextInput] = useState(false);
    const [displayColorPicker, setDisplayColorPicker] = useState(false);
    const [hexCode, setHexCode] = useState("#F17013");
    const [color, setColor] = useState({
        r: "241",
        g: "112",
        b: "19",
        a: "1",
    });
    const [framesize, setFrameSize] = useState("");
    const [frameThickness, setFrameThickness] = useState("");
    const [frameQuantity, setFrameQuantity] = useState(1);
    const [productData, setProductData] = useState([]);
    const [productName, setProductName] = useState("");
    const user = localStorage.getItem("user");
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const productsRef = collection(db, `AllProducts/admin/Frame`);
        const q = query(productsRef);
        onSnapshot(q, (querySnapshot) => {
            setProductData(
                querySnapshot.docs.map((doc) => ({
                    id: doc.id,
                    data: doc.data(),
                }))
            );
        });
    }, []);

    console.log(productData);
    useEffect(() => {
        if (productData.length > 0) {
            productData.map((prod) => {
                setImageData(prod.data.defaultImage);
                setFrameColor(prod.data.defaultFrameColour.colour);
                setProductName(prod.data.productName);
                setFrameSize(prod.data.size[0]);
                setFrameThickness(prod.data.thickness[0]);
            });
        }
    }, [productData]);

    useEffect(() => {
        const updateCanvas = (canvas, index) => {
            const ctx = canvas.getContext("2d");
            if (imageData) {
                const img = new Image();
                img.src = imageData;
                img.onload = () => {
                    if (index <= 2) {
                        canvas.width = 350;
                        canvas.height = 400;
                        ctx.lineWidth = 15;
                        ctx.font = "bold 30px 'Dancing Script', cursive";
                    } else {
                        canvas.width = 100;
                        canvas.height = 150;
                        ctx.lineWidth = 10;
                        ctx.font = "bold 10px 'Dancing Script', cursive";
                    }
                    ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
                    ctx.strokeStyle = frameColor;
                    ctx.strokeRect(0, 0, canvas.width, canvas.height);
                    ctx.textAlign = "center";
                    ctx.textBaseline = "bottom";
                    ctx.fillStyle = hexCode;
                    ctx.fillText(imageText, canvas.width / 2, canvas.height - 20);
                };
            }
        };
        canvasRefs.current.forEach((canvas, index) => updateCanvas(canvas, index));
    }, [imageData, frameColor, imageText, color, hexCode]);

    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        setImageUpload(file);
        const reader = new FileReader();
        reader.onload = () => {
            setImageData(reader.result);
        };
        reader.readAsDataURL(file);
    };

    const handleClick = () => {
        setDisplayColorPicker(!displayColorPicker);
    };

    const handleClose = () => {
        setDisplayColorPicker(false);
    };

    const handleChange = (selectedColor) => {
        setColor(selectedColor.rgb);
        setHexCode(selectedColor.hex);
    };

    const styles = reactCSS({
        default: {
            color: {
                width: "36px",
                height: "36px",
                borderRadius: "2px",
                background: `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`,
            },
            swatch: {
                padding: "5px",
                background: "#fff",
                borderRadius: "1px",
                boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
                display: "inline-block",
                cursor: "pointer",
            },
            popover: {
                position: "absolute",
                zIndex: "2",
            },
            cover: {
                position: "fixed",
                top: "0px",
                right: "0px",
                bottom: "0px",
                left: "0px",
            },
        },
    });
    const navigate = useNavigate();
    const [modalShow, setModalShow] = useState(false);
    const handleImageUploadToDataBase = () => {
        if (localStorage.getItem("user")) {
            if (imageUpload == null) {
                ErrorToast("Please Upload The Image");
                return;
            } else {
                setLoading(true);
                const imageRef = ref(
                    firebaseStorage,
                    `images/${user}/${imageUpload.name + v4()}`
                );
                uploadBytes(imageRef, imageUpload)
                    .then((snapshot) => {
                        getDownloadURL(snapshot.ref)
                            .then((url) => {
                                const productsRef = collection(db, `Allusers/${user}/cart`);
                                const product = {
                                    image: url,
                                    frameColor,
                                    showTextInput,
                                    imageText,
                                    textColor: color,
                                    framesize: framesize.size,
                                    frameThickness: frameThickness.thickness,
                                    frameQuantity,
                                    textColorHexCode: hexCode,
                                    created: Timestamp.now(),
                                    price: +framesize.price + +frameThickness.price,
                                    imageName: snapshot.metadata.name,
                                    productName,
                                };
                                addDoc(productsRef, product)
                                    .then((docRef) => {
                                        SuccessToast("Item Has Been Added To The Cart");
                                        console.log(
                                            "Product information and image URL stored successfully"
                                        );
                                        setLoading(false);
                                    })

                                    .catch((error) => {
                                        console.error(
                                            "Error storing product information and image URL",
                                            error
                                        );
                                        ErrorToast("Something Went Wrong Please Try Again Later");
                                        setLoading(false);
                                    });
                            })
                            .catch((error) => {
                                console.error("Error retrieving download URL", error);
                                ErrorToast("Something Went Wrong Please Try Again Later");
                                setLoading(false);
                            });
                    })
                    .catch((error) => {
                        console.error("Error uploading image", error);
                        ErrorToast("Something Went Wrong Please Try Again Later");
                        setLoading(false);
                    });
            }
        } else {
            setModalShow(true);
        }
    };

    return (
        <>
            {modalShow === true && (
                <LoginAndSignUpModal
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                />
            )}
            <div className="single-product-container">
                <section className="single-product">
                    <div className="container">
                        {productData.length > 0 &&
                            productData.map((prod) => (
                                <div key={prod.id}>
                                    <div className="row">
                                        <div className="col-md-6 col-lg-6 margin__table-view">
                                            <div className="single-product-slider">
                                                <div
                                                    className="carousel slide"
                                                    data-ride="carousel"
                                                    id="single-product-slider"
                                                >
                                                    <div className="carousel-inner">
                                                        {prod.data.backGroundImage.map((image, index) => (
                                                            <div
                                                                key={image}
                                                                className={
                                                                    index === 0
                                                                        ? `carousel-item active`
                                                                        : `carousel-item`
                                                                }
                                                                style={{
                                                                    backgroundImage: `url(${image.image})`,
                                                                    backgroundPosition: "center",
                                                                    backgroundRepeat: "no-repeat",
                                                                    backgroundSize: "cover",
                                                                }}
                                                            >
                                                                <div
                                                                    className="d-flex justify-content-center align-items-center canvas-div"
                                                                    ref={(el) =>
                                                                        (containerRef.current[index] = el)
                                                                    }
                                                                >
                                                                    <canvas
                                                                        ref={(el) =>
                                                                            (canvasRefs.current[index] = el)
                                                                        }
                                                                        className="canvas-frame img-fluid"
                                                                    ></canvas>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>

                                                    <ol className="carousel-indicators">
                                                        {prod.data.backGroundImage.map((image, index) => (
                                                            <li
                                                                key={image}
                                                                data-target="#single-product-slider"
                                                                data-slide-to={index}
                                                                className={index === 0 ? "active" : ""}
                                                                style={{
                                                                    backgroundImage: `url(${image.image})`,
                                                                    backgroundPosition: "center",
                                                                    backgroundRepeat: "no-repeat",
                                                                    backgroundSize: "cover",
                                                                    width: "33.3%",
                                                                    height: "100%",
                                                                }}
                                                            >
                                                                <div
                                                                    className="d-flex justify-content-center align-items-center canvas-div_2"
                                                                    ref={(el) =>
                                                                    (containerRef.current[
                                                                        prod.data.backGroundImage.length + index
                                                                    ] = el)
                                                                    }
                                                                >
                                                                    <canvas
                                                                        className="canvas-frame-2 img-fluid"
                                                                        ref={(el) =>
                                                                        (canvasRefs.current[
                                                                            prod.data.backGroundImage.length + index
                                                                        ] = el)
                                                                        }
                                                                    ></canvas>
                                                                </div>
                                                            </li>
                                                        ))}
                                                    </ol>
                                                </div>
                                            </div>
                                            {showTextInput === true ? (
                                                <div className="d-flex">
                                                    <div
                                                        className="quantity d-flex justify-content-start"
                                                        style={{ width: "100%" }}
                                                    >
                                                        <input
                                                            type="text"
                                                            placeholder="e.g. Happy Birthday 🎂 / ❤️ Anniversery / Love"
                                                            style={{
                                                                display: "inline-block",
                                                                backgroundColor: "transparent",
                                                                boxShadow: "none",
                                                                outline: "none",
                                                                border: "1px solid #ddd",
                                                                padding: "0 0.75em",
                                                                width: "85%",
                                                                verticalAlign: "middle",
                                                            }}
                                                            className="input__text-container"
                                                            onChange={(e) => setImageText(e.target.value)}
                                                            maxLength={35}
                                                        />
                                                        <div
                                                            className="quantity d-flex "
                                                            style={{ width: "20%", marginLeft: "20px" }}
                                                        >
                                                            <div style={styles.swatch} onClick={handleClick}>
                                                                <div style={styles.color} />
                                                            </div>
                                                            {displayColorPicker ? (
                                                                <div style={styles.popover}>
                                                                    <div
                                                                        style={styles.cover}
                                                                        onClick={handleClose}
                                                                    />
                                                                    <SketchPicker
                                                                        color={color}
                                                                        onChange={handleChange}
                                                                    />
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : (
                                                <></>
                                            )}
                                        </div>

                                        <div className="col-md-6 col-lg-5">
                                            <div className="single-product-details mt-5 mt-lg-0">
                                                <h2>{productName}</h2>
                                                <div className="sku_wrapper mb-4">
                                                    SKU:{" "}
                                                    <span className="text-muted">
                                                        {prod.data.productId}{" "}
                                                    </span>
                                                </div>

                                                <hr />

                                                <h3 className="product-price">
                                                    &#8377;{+framesize.price + +frameThickness.price}{" "}
                                                    {/* <del>&#8377;{prod.data.price}</del> */}
                                                </h3>

                                                <p className="product-description my-4 ">
                                                    {prod.data.productDescription}
                                                </p>
                                                <div className="cart mb-2">
                                                    <div className="d-flex">
                                                        <div className="quantity d-flex align-items-center mr-4">
                                                            <label
                                                                htmlFor="photoInput"
                                                                className="btn btn-success"
                                                                style={{ cursor: "pointer" }}
                                                            >
                                                                Select Photo
                                                            </label>
                                                            <input
                                                                id="photoInput"
                                                                type="file"
                                                                className="d-none"
                                                                onChange={handleImageUpload}
                                                            />
                                                        </div>

                                                        <div
                                                            className="quantity d-flex align-items-center flex-column"
                                                            onClick={() => setShowTextInput(true)}
                                                            style={{ marginTop: "-10px" }}
                                                        >
                                                            <img
                                                                style={{
                                                                    maxWidth: "25px",
                                                                    display: "block",
                                                                    margin: "auto",
                                                                    cursor: "pointer",
                                                                }}
                                                                src="assets/images/text.png"
                                                                alt="Text"
                                                            />
                                                            <span
                                                                style={{
                                                                    fontWeight: "bolder",
                                                                    fontSize: "15px",
                                                                    marginTop: "-10px",
                                                                }}
                                                            >
                                                                Text
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="cart">
                                                    <div className="quantity d-flex align-items-center">
                                                        <input
                                                            type="number"
                                                            className="input-text qty text form-control w-25 mr-3"
                                                            step="1"
                                                            min="1"
                                                            max="10"
                                                            name="quantity"
                                                            value={frameQuantity}
                                                            title="Qty"
                                                            size="4"
                                                            onChange={(e) => {
                                                                setFrameQuantity(e.target.value);
                                                            }}
                                                        />
                                                        <button
                                                            type="submit"
                                                            onClick={handleImageUploadToDataBase}
                                                            className="btn btn-main btn-small"
                                                        >
                                                            {!loading && (
                                                                <span className="indicator-label">
                                                                    {" "}
                                                                    Add to cart
                                                                </span>
                                                            )}
                                                            {loading && (
                                                                <span
                                                                    className="indicator-progress"
                                                                    style={{ display: "block" }}
                                                                >
                                                                    Please wait...
                                                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                                </span>
                                                            )}
                                                        </button>
                                                    </div>
                                                </div>

                                                <div className="color-swatches mt-4 d-flex align-items-center">
                                                    <span className="font-weight-bold text-capitalize product-meta-title">
                                                        color:
                                                    </span>
                                                    <ul
                                                        className="list-inline mb-0"
                                                        style={{ cursor: "pointer" }}
                                                    >
                                                        {prod.data.frameColors.map((color) => (
                                                            <li
                                                                className="list-inline-item"
                                                                onClick={() => {
                                                                    setFrameColor(color.colour);
                                                                }}
                                                                key={color}
                                                            >
                                                                <a
                                                                    style={{ backgroundColor: color.colour }}
                                                                ></a>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>

                                                <div className="product-size d-flex align-items-center mt-4">
                                                    <div className="d-flex">
                                                        <span className="font-weight-bold product-meta-title mr-3">
                                                            Size (Inch)
                                                        </span>
                                                        <select
                                                            className="form-control"
                                                            style={{ textTransform: "lowercase" }}
                                                            onChange={(e) => {
                                                                const newSize = prod.data.size.find(
                                                                    (item) => item.size === e.target.value
                                                                );
                                                                setFrameSize(newSize);
                                                            }}
                                                        >
                                                            {prod.data.size.map((size) => (
                                                                <option key={size.size} value={size.size}>
                                                                    {size.size}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                    <div className="d-flex">
                                                        <span className="font-weight-bold product-meta-title mr-5">
                                                            Thickness (mm)
                                                        </span>
                                                        <select
                                                            className="form-control"
                                                            style={{ textTransform: "lowercase" }}
                                                            onChange={(e) => {
                                                                const newThickness = prod.data.thickness.find(
                                                                    (item) => item.thickness === e.target.value
                                                                );
                                                                setFrameThickness(newThickness);
                                                            }}
                                                        >
                                                            {prod.data.thickness.map((thickness) => (
                                                                <option
                                                                    key={thickness.thickness}
                                                                    value={thickness.thickness}
                                                                >
                                                                    {thickness.thickness}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>

                                                <div className="products-meta mt-4">
                                                    <div className="product-category">
                                                        <span className="font-weight-bold text-capitalize product-meta-title">
                                                            Categories :
                                                        </span>{" "}
                                                        {prod.data.categories}
                                                    </div>

                                                    <div className="product-share mt-5">
                                                        <ul className="list-inline">
                                                            <li className="list-inline-item">
                                                                <a href="#">
                                                                    <i className="tf-ion-social-facebook"></i>
                                                                </a>
                                                            </li>
                                                            <li className="list-inline-item">
                                                                <a href="#">
                                                                    <i className="tf-ion-social-twitter"></i>
                                                                </a>
                                                            </li>
                                                            <li className="list-inline-item">
                                                                <a href="#">
                                                                    <i className="tf-ion-social-linkedin"></i>
                                                                </a>
                                                            </li>
                                                            <li className="list-inline-item">
                                                                <a href="#">
                                                                    <i className="tf-ion-social-pinterest"></i>
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-lg-12">
                                            <nav className="product-info-tabs wc-tabs mt-5 mb-5">
                                                <div
                                                    className="nav nav-tabs nav-fill"
                                                    id="nav-tab"
                                                    role="tablist"
                                                >
                                                    <a
                                                        className="nav-item nav-link active"
                                                        id="nav-home-tab"
                                                        data-toggle="tab"
                                                        href="#nav-home"
                                                        role="tab"
                                                        aria-controls="nav-home"
                                                        aria-selected="true"
                                                    >
                                                        Description
                                                    </a>
                                                    <a
                                                        className="nav-item nav-link"
                                                        id="nav-profile-tab"
                                                        data-toggle="tab"
                                                        href="#nav-profile"
                                                        role="tab"
                                                        aria-controls="nav-profile"
                                                        aria-selected="false"
                                                    >
                                                        Additional Information
                                                    </a>
                                                </div>
                                            </nav>

                                            <div className="tab-content" id="nav-tabContent">
                                                <div
                                                    className="tab-pane fade show active"
                                                    id="nav-home"
                                                    role="tabpanel"
                                                    aria-labelledby="nav-home-tab"
                                                >
                                                    <p>
                                                        Pellentesque habitant morbi tristique senectus et
                                                        netus et malesuada fames ac turpis egestas.
                                                        Vestibulum tortor quam, feugiat vitae, ultricies
                                                        eget, tempor sit amet, ante. Donec eu libero sit
                                                        amet quam egestas semper. Aenean ultricies mi vitae
                                                        est. Mauris placerat eleifend leo.
                                                    </p>

                                                    <h4>Product Features</h4>

                                                    <ul className="">
                                                        <li>
                                                            Mapped with 3M™ Thinsulate™ Insulation [40G Body /
                                                            Sleeves / Hood]
                                                        </li>
                                                        <li>Embossed Taffeta Lining</li>
                                                        <li>
                                                            DRYRIDE Durashell™ 2-Layer Oxford Fabric
                                                            [10,000MM, 5,000G]
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div
                                                    className="tab-pane fade"
                                                    id="nav-profile"
                                                    role="tabpanel"
                                                    aria-labelledby="nav-profile-tab"
                                                >
                                                    <ul className="list-unstyled info-desc">
                                                        <li className="d-flex">
                                                            <strong>Weight </strong>
                                                            <span>400 g</span>
                                                        </li>
                                                        <li className="d-flex">
                                                            <strong>Dimensions </strong>
                                                            <span>10 x 10 x 15 cm</span>
                                                        </li>
                                                        <li className="d-flex">
                                                            <strong>Materials</strong>
                                                            <span>60% cotton, 40% polyester</span>
                                                        </li>
                                                        <li className="d-flex">
                                                            <strong>Color </strong>
                                                            <span>Blue, Gray, Green, Red, Yellow</span>
                                                        </li>
                                                        <li className="d-flex">
                                                            <strong>Size</strong>
                                                            <span>L, M, S, XL, XXL</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                                {/* <div
                                                    className="tab-pane fade"
                                                    id="nav-contact"
                                                    role="tabpanel"
                                                    aria-labelledby="nav-contact-tab"
                                                >
                                                    <div className="row">
                                                        <div className="col-lg-7">
                                                            <div className="media review-block mb-4">
                                                                <img
                                                                    src="assets/images/avater-1.jpg"
                                                                    alt="reviewimg"
                                                                    className="img-fluid mr-4"
                                                                />
                                                                <div className="media-body">
                                                                    <div className="product-review">
                                                                        <span>
                                                                            <i className="tf-ion-android-star"></i>
                                                                        </span>
                                                                        <span>
                                                                            <i className="tf-ion-android-star"></i>
                                                                        </span>
                                                                        <span>
                                                                            <i className="tf-ion-android-star"></i>
                                                                        </span>
                                                                        <span>
                                                                            <i className="tf-ion-android-star"></i>
                                                                        </span>
                                                                        <span>
                                                                            <i className="tf-ion-android-star"></i>
                                                                        </span>
                                                                    </div>
                                                                    <h6>
                                                                        Therichpost{" "}
                                                                        <span className="text-sm text-muted font-weight-normal ml-3">
                                                                            -June 23, 2019
                                                                        </span>
                                                                    </h6>
                                                                    <p>
                                                                        Lorem ipsum dolor sit amet, consectetur
                                                                        adipisicing elit. Ipsum suscipit
                                                                        consequuntur in, perspiciatis laudantium
                                                                        ipsa fugit. Iure esse saepe error dolore
                                                                        quod.
                                                                    </p>
                                                                </div>
                                                            </div>

                                                            <div className="media review-block">
                                                                <img
                                                                    src="assets/images/avater-2.jpg"
                                                                    alt="reviewimg"
                                                                    className="img-fluid mr-4"
                                                                />
                                                                <div className="media-body">
                                                                    <div className="product-review">
                                                                        <span>
                                                                            <i className="tf-ion-android-star"></i>
                                                                        </span>
                                                                        <span>
                                                                            <i className="tf-ion-android-star"></i>
                                                                        </span>
                                                                        <span>
                                                                            <i className="tf-ion-android-star"></i>
                                                                        </span>
                                                                        <span>
                                                                            <i className="tf-ion-android-star"></i>
                                                                        </span>
                                                                        <span>
                                                                            <i className="tf-ion-android-star-outline"></i>
                                                                        </span>
                                                                    </div>
                                                                    <h6>
                                                                        Therichpost{" "}
                                                                        <span className="text-sm text-muted font-weight-normal ml-3">
                                                                            -June 23, 2019
                                                                        </span>
                                                                    </h6>
                                                                    <p>
                                                                        Lorem ipsum dolor sit amet, consectetur
                                                                        adipisicing elit. Ipsum suscipit
                                                                        consequuntur in, perspiciatis laudantium
                                                                        ipsa fugit. Iure esse saepe error dolore
                                                                        quod.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-lg-5">
                                                            <div className="review-comment mt-5 mt-lg-0">
                                                                <h4 className="mb-3">Add a Review</h4>

                                                                <form action="#">
                                                                    <div className="starrr"></div>
                                                                    <div className="form-group">
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            placeholder="Your Name"
                                                                        />
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <input
                                                                            type="email"
                                                                            className="form-control"
                                                                            placeholder="Your Email"
                                                                        />
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <textarea
                                                                            name="comment"
                                                                            id="comment"
                                                                            className="form-control"
                                                                            cols="30"
                                                                            rows="4"
                                                                            placeholder="Your Review"
                                                                        ></textarea>
                                                                    </div>

                                                                    <a
                                                                        href="product-single"
                                                                        className="btn btn-main btn-small"
                                                                    >
                                                                        Submit Review
                                                                    </a>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                    </div>
                </section>
            </div>
        </>
    );
}
export default SingleProduct;
