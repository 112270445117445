import { useState } from "react";
import FAQ from "./FAQ";
import "./FAQstyles.css";

function Home() {
    const [faqs, setFaqs] = useState([
        {
            question: "How many programmers does it take to screw a lightbulb?",
            answer:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed pharetra lorem eu dolor rhoncus, at scelerisque ligula gravida. Sed porta id mi sit amet convallis. Etiam iaculis massa sit amet lacus blandit sodales. Nulla ultrices velit a diam placerat congue. Pellentesque iaculis, ipsum quis eleifend dapibus, est dui eleifend ante, quis fermentum mi ligula quis nisl. Ut et ex dui. Integer id venenatis quam.",
            open: false,
        },
        {
            question: "Who is the most awesome person?",
            answer: "You! The viewer!",
            open: false,
        },
        {
            question:
                "How many questions does it take to makes a succesful FAQ Page?",
            answer: "This many!",
            open: false,
        },
    ]);

    const toggleFAQ = (index) => {
        setFaqs(
            faqs.map((faq, i) => {
                if (i === index) {
                    faq.open = !faq.open;
                } else {
                    faq.open = false;
                }

                return faq;
            })
        );
    };

    const textOverlayStyles = {
        backgroundColor: "#ff9248",
        color: "#fff",
        padding: "10px",
        fontSize: "18px",
        fontWeight: "bold",
        textAlign: "center",
    };

    return (
        <div className="home-container">
            <img
                src="assets/images/banner.png"
                style={{
                    backgroundPosition: "top",
                    backgroundSize: "cover",
                    width: "100vw",
                }}
            />
            <div className="main-slider slider slick-initialized slick-slider"></div>
            {/* <section className="category section pt-3 pb-0">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-sm-12 col-md-6">
                            <div className="cat-item mb-4 mb-lg-0">
                                <img src="assets/images/cat-1.jpg" alt="" className="img-fluid" />
                                <div className="item-info">
                                    <p className="mb-0">Stylish Leather watch</p>
                                    <h4 className="mb-4">
                                        up to <strong>50% </strong>off
                                    </h4>
                                    <a href="#" className="read-more">
                                        Shop now
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-12 col-md-6">
                            <div className="cat-item mb-4 mb-lg-0">
                                <img src="assets/images/cat-2.jpg" alt="" className="img-fluid" />
                                <div className="item-info">
                                    <p className="mb-0">Ladies hand bag</p>
                                    <h4 className="mb-4">
                                        up to <strong>40% </strong>off
                                    </h4>
                                    <a href="#" className="read-more">
                                        Shop now
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-12 col-md-6">
                            <div className="cat-item">
                                <img src="assets/images/cat-3.jpg" alt="" className="img-fluid" />
                                <div className="item-info">
                                    <p className="mb-0">Trendy shoe</p>
                                    <h4 className="mb-4">
                                        up to <strong>50% </strong>off
                                    </h4>
                                    <a href="#" className="read-more">
                                        Shop now
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
            <section className="section products-main">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-12 col-md-6 col-sm-6 mb-5">
                            <div className="product h-100">
                                <div className="product-wrap h-100">
                                    <a href="/frames">
                                        <img
                                            className="img-fluid w-100 h-100 mb-3 img-first"
                                            src="assets/images/home_image_frame.jpg"
                                            alt="product-img"
                                        />
                                    </a>
                                </div>
                                <span class="onsale">Sale</span>
                                <div className="product-hover-overlay">
                                    <a href="/frames">
                                        <i className="tf-ion-android-cart"></i>
                                    </a>
                                </div>
                            </div>
                            <div className="text-overlay" style={textOverlayStyles}>
                                Acrylic Photo
                            </div>
                        </div>

                        <div className="col-lg-6 col-12 col-md-6 col-sm-6 mb-5">
                            <div className="product h-100">
                                <div className="product-wrap h-100">
                                    <a href="/clock">
                                        <img
                                            className="img-fluid w-100 h-100 mb-3 img-first"
                                            src="assets/images/home_images_clock.jpg"
                                            alt="product-img"
                                        />
                                    </a>
                                </div>
                                <span class="onsale">Sale</span>
                                <div className="product-hover-overlay">
                                    <a href="/clock">
                                        <i className="tf-ion-android-cart"></i>
                                    </a>
                                </div>
                            </div>
                            <div className="text-overlay" style={textOverlayStyles}>
                                Acrylic Wall Clock
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="features">
                <div className="container">
                    <div
                        style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            position: "relative",
                            justifyContent: "space-between",
                            flexFlow: "row-wrap",
                        }}
                    >
                        <b
                            style={{
                                display: "block",
                                flex: "1",
                                height: "2px",
                                opacity: ".1",
                                backgroundColor: "#000",
                            }}
                        ></b>
                        <div style={{ textAlign: "center", margin: "0 15px" }}>
                            FREQUENTLY ASKED QUESTIONS
                        </div>
                        <b
                            style={{
                                display: "block",
                                flex: "1",
                                height: "2px",
                                opacity: ".1",
                                backgroundColor: "#000",
                            }}
                        ></b>
                    </div>
                    <div className="faqs">
                        {faqs.map((faq, index) => (
                            <FAQ faq={faq} index={index} key={index} toggleFAQ={toggleFAQ} />
                        ))}
                    </div>
                </div>
            </section>
        </div>
    );
}
export default Home;
